import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`The figure in the tarnished metal suit loomed over Gael. It was impossible to discern any features through the scratched and dirty glass of the viewport in the helmet, but he could only imagine a contorted mass of tentacles, eyes, and mottled grey flesh beneath the placid mask. Without warning it turned and launched it's impossible mass over the side of the ship and into the depths below.`}</em></p>
    <div style={{
      "textAlign": "right"
    }}>—Gael Cairright, Of The Sea and Below</div>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/80c95a51b87a79598280b49dc1a24c0a/aa440/Morne_A_humanoid_deep_sea_creature_wearing_a_deep_sea_diving_su_c28ba0d5-ed25-471a-b0e1-4c34ddf8b4bb.png",
            "srcSet": ["/static/80c95a51b87a79598280b49dc1a24c0a/5ff7e/Morne_A_humanoid_deep_sea_creature_wearing_a_deep_sea_diving_su_c28ba0d5-ed25-471a-b0e1-4c34ddf8b4bb.png 375w", "/static/80c95a51b87a79598280b49dc1a24c0a/1d69c/Morne_A_humanoid_deep_sea_creature_wearing_a_deep_sea_diving_su_c28ba0d5-ed25-471a-b0e1-4c34ddf8b4bb.png 750w", "/static/80c95a51b87a79598280b49dc1a24c0a/aa440/Morne_A_humanoid_deep_sea_creature_wearing_a_deep_sea_diving_su_c28ba0d5-ed25-471a-b0e1-4c34ddf8b4bb.png 1500w", "/static/80c95a51b87a79598280b49dc1a24c0a/71c1d/Morne_A_humanoid_deep_sea_creature_wearing_a_deep_sea_diving_su_c28ba0d5-ed25-471a-b0e1-4c34ddf8b4bb.png 1536w"],
            "width": "550px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Deep Dweller on the streets of Meripol`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The Msanti, or the Dwellers in the Deep, as they are otherwise known, are humanoid creatures that live in the depths of the ocean. It is said that long ago, they were banished to the sea after an indiscretion with the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Water",
        "title": "Primal Spirit of Water"
      }}>{`Primal Spirit of Water`}</a>{`.`}</p>
    <h2>{`Immense Pressure`}</h2>
    <p>{`The Msanti live in the darkest depths of the ocean, where the pressure of water would crush land lopers. While they rarely leave their solitary existence, the more adventurous Deep Dwellers don pressurized suits that allow them to ascend to the surface and explore the overworld. These suits are made to be self-sustaining, keeping the Deep Dwellers that use them in water and under enough pressure to retain their form that would otherwise collapse while on the surface. The suits are fitted with mirror-like visors—most Deep Dwellers have extraordinarily sensitive eyes to live in the near-dark of the fathoms.`}</p>
    <h2>{`Life at the Bottom`}</h2>
    <p>{`Although Deep Dwellers live where very little light exists, natural geothermal vents along the ocean floor provide a natural source of energy to allow their civilization to thrive. Deep Dweller civilizations are wide and varied, from vast cultivated fields of seaweed, to small villages and large cities glowing with bioluminescent light. They have harnessed the geothermal power available to them to acheive technological prowess, creating mechanical feats that can operate even at the bottom of the ocean. With the use of hydrothermal vents and magic, the Msanti are known for their excellent metallurgy. Land dwellers often travel to Msanti trading bazaars found in shallower water in order to trade.`}</p>
    <p>{`The Deep Dwellers themselves represent a wide array of life. Some are nearly humanoid, the only differences being larger eyes to take in more light, and a lack of pigment to their skin. Others, however, can be more otherwordly, featuring an array of tentacles, teeth, bioluminescent fins, exoskeletons, and more. Nearly all Deep Dwellers have retained their bipedal form, though, as it allows them to walk along the sea floor and travel on land.`}</p>
    <h3>{`Communication is Key`}</h3>
    <p>{`Although many Deep Dwellers tend to prefer solitary life, their societal advancement necessitated collaboration. Verbal speech an impossibility underwater, they have a means to communicate telepathically between themselves and other sentient creatures.`}</p>
    <h3>{`Cursed Past`}</h3>
    <p>{`While the exact origins of the Deep Dwellers is unclear, it is mostly agreed upon that they were once human, long ago. Their ancestors vied for more power and tried to capture and enslave the primal spirit of water. In their failure, they were banished from land by the spirit, cursing them to live at the bottom of the sea. At first, the deep dwellers lived alone in darkness. Eventually, though, they developed into a flourshing society beneath the waves.`}</p>
    <p>{`While many Deep Dwellers are contented with their new existence, some Deep Dwellers long to return to the surface permenately, in an event known as `}<a parentName="p" {...{
        "href": "/The%20Ascension",
        "title": "The Ascension"
      }}>{`The Ascension`}</a>{`. They believe that if the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Water",
        "title": "Primal Spirit of Water"
      }}>{`Primal Spirit`}</a>{` was the one to banish them, it has the power to return them to human form and allow them to rejoin the surface lopers. Some Deep Dwellers go on great pilgramages across the land and sea in search of a way to appease the spirit and begin `}<a parentName="p" {...{
        "href": "/The%20Ascension",
        "title": "The Ascension"
      }}>{`The Ascension`}</a>{`.`}</p>
    <h2>{`Msanti Names`}</h2>
    <p>{`Names are usually a feeling, or alike specific sensation or thought or idea.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      